.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    margin-bottom: 80px;
    z-index: 99;

    .wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 80px 100px 0;
    }

    .left-block,
    .right-block {
        display: flex;
        align-items: center;
    }

    .logo {
        max-width: 130px;
        margin-right: 80px;
    }
    .site-name {
        font-size: 16px;
    }

    .phone {
        display: flex;
        align-items: center;
        font-family: $ProximaNovaSemibold;
        font-size: 24px;
        color: $black;

        .icon {
            margin-right: 10px;
        }
    }

    .button-wrap {
        display: flex;
        align-items: center;
    }

    .become-client {
        display: flex;
        align-items: flex-end;
        margin-left: 60px;
        padding: 0 30px;
        background: $yellow;
        font-size: 16px;
        color: $black;
        text-transform: uppercase;
        border-radius: 50px;

        .icon {
            margin: -13px 10px 0 0;
            max-width: 56px;
        }

        .text {
            display: flex;
            align-items: center;
            height: 54px;
        }
    }


    .call-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 54px;
        margin-left: 60px;
        padding: 0 30px;
        font-size: 16px;
        font-family: $ProximaNovaSemibold;
        font-weight: 600;
        color: $black;
        text-align: center;
        text-transform: uppercase;
        border: 1px solid $black;
        border-radius: 50px;
    }
}


@media (max-width: 1601px) {
    .header {
        .logo {
            margin-right: 40px;
        }
        .call-btn,
        .become-client {
            margin-left: 40px;
        }
    }
}

@media (max-width: 1441px) {
    .header {
        .wrap {
            padding: 50px 50px 0;
        }
    }
}

@media (max-width: 1301px) {
    .header {
        .left-block {
            flex-direction: column;
            align-items: flex-start;
        }

        .logo {
            margin: 0 0 20px;
        }
        .site-name {
            padding: 0 0 0 23px;
        }

        .become-client {
            margin-left: 20px;

            .icon {
                margin-top: -22px;
            }
            .text {
                height: 44px;
            }
        }

        .call-btn {
            height: 44px;
            margin-left: 20px;
        }
    }
}

@media (max-width: 1281px) {
    .header {
        .wrap {
            padding: 30px 30px 0;
        }
        .right-block {
            justify-content: flex-end;
            flex-wrap: wrap;
        }
        .button-wrap {
            margin-top: 20px;
            flex: 100%;
            justify-content: flex-end;
        }
    }
}

@media (max-width: 993px) {
    .header {
        .wrap {
            padding: 30px 20px 0;
        }
    }
}

@media (max-width: 769px) {
    .header {
        .wrap {
            align-items: stretch;
        }

        .logo {
            margin: 0 0 10px;
        }

        .site-name {
            padding: 0;
            font-size: 12px;
        }

        .phone {
            font-size: 20px;
        }

        .button-wrap {
            flex-direction: column;
            align-items: flex-end;
        }

        .become-client {
            margin: 10px 0 0;

            .text {
                font-size: 14px;
            }
        }

        .call-btn {
            margin: 10px 0 0;
            font-size: 14px;
        }
    }
}

@media (max-width: 376px) {
    .header {
        .wrap {
            flex-direction: column;
        }

        .right-block {
            justify-content: flex-start;
            padding-top: 20px;
        }

        .button-wrap {
            align-items: flex-start;
        }
    }
}