/*** Modal ***/

.modal {
    position: relative;
    width: 90%;
    max-width: 1500px;

    .wrap {
        position: relative;
        max-height: 75%;
        padding: 70px 80px;
        background: $white;
        overflow: auto;
        border-radius: 5px;
        background-size: cover;
        box-shadow: 0 0 40px $black;
        z-index: 99;
    }

    &:before {
        content: '';
        position: absolute;
        top: 15px;
        left: 50%;
        transform: translate(-50%);
        background: $white;
        width: calc(100% - 40px);
        height: 100%;
        max-width: 1500px;
        border-radius: 5px;
        box-shadow: 0 0 40px $black;
        z-index: 98;
    }
    &:after {
        content: '';
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%);
        background: $white;
        width: calc(100% - 70px);
        height: 100%;
        max-width: 1500px;
        border-radius: 5px;
        box-shadow: 0 0 40px $black;
        z-index: 9;
    }

    .modal-close-btn {
        position: absolute;
        top: 40px;
        right: 40px;
        width: 36px;
        height: 36px;
        cursor: pointer;
        z-index: 999;

        &:before,
        &:after {
            content: '';
            display: block;
            width: 36px;
            height: 2px;
            background: $black;
            transform: rotate(45deg);
            margin: 13px 0 0;
        }

        &:after {
            margin: -2px 0 0;
            transform: rotate(-45deg);
        }
    }

    &-wrap {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        z-index: 999;

        &.show {
            opacity: 1;
            pointer-events: all;
        }

        .overflow {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $black;
            opacity: 0.7;
            cursor: pointer;
        }


        &.phone-modal {
            .modal {
                max-width: 973px;
                text-align: center;

                &:before,
                &:after {
                    max-width: 973px;
                }

                .wrap {
                    padding: 100px;
                }
            }

            .sub-title {
                max-width: 70%;
                margin: 10px auto 0;
            }

            .form {
                margin-top: 20px;
            }
        }
    }

    .info-text {
        display: flex;
        align-items: center;
        max-width: 430px;
        margin: 30px auto 0;
        font-size: 12px;
        font-family: $ProximaNovaLight;
        text-align: left;

        .icon {
            min-width: 22px;
            margin-right: 10px;
        }
    }

    .title {
        font-size: 64px;
        line-height: 64px;
        font-family: $ProximaNovaSemibold;
    }

    .sub-title {
        margin-top: 10px;
    }

    .form {
        margin-top: 40px;

        .row {
            margin-left: -10px;
        }

        .col {
            margin: 10px 0 0 10px;

            &:last-child {
                flex: 2;
            }
        }

        .input {
            padding: 0 30px;
            font-size: 20px;
            font-family: $ProximaNovaBoldItalic;
        }

        .btn {
            width: 100%;
            padding: 30px;
        }

        .col-wrap {
            display: flex;

            .left-col {
                display: flex;
                flex: 1;
                padding-right: 15px;

                .row {
                    display: flex;
                    flex: 1;
                }

                .col {
                    display: flex;
                    height: 100%;
                }

                .input-wrap {
                    flex: 1;
                }

                .textarea {
                    height: 100%;
                }
            }
            .right-col {
                flex: 1;
                padding-left: 15px;
            }

            .col {
                max-width: 100%;
            }
        }
    }

    .offer-block {
        display: flex;

        .left-block {
            flex: 1;
            padding-right: 70px;
        }
        .right-block {
            flex: 1;
            max-width: 475px;
            padding-top: 90px;
        }

        .sub-title {
            position: relative;
            max-width: 470px;
            margin-top: 20px;
            padding-left: 90px;
            font-size: 31px;

            &:before {
                content: '';
                position: absolute;
                top: 15px;
                left: 20px;
                height: 1px;
                width: 30px;
                background: $black;
            }
        }

        .form {
            margin: 0;

            .row {
                margin-top: 0;
            }
            .col {
                max-width: 100%;
            }
        }

        .info-text {
            padding: 0 20px;
        }
    }
}

.benefits-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;

    &-item {
        display: flex;
        flex: 1;
        padding-left: 40px;

        .title {
            font-size: 27px;
            line-height: 27px;
            font-family: $ProximaNovaSemibold;
        }

        &:first-child {
            flex: 2;
            max-width: 450px;
            padding-left: 0;
        }
    }

    &-title {
        padding-right: 50px;
        font-size: 41px;
        line-height: 41px;
        font-family: $ProximaNovaLight;
    }

    .num {
        margin: 0 40px 20px 0;
        font-family: $ProximaNovaBoldItalic;
        font-size: 100px;
        line-height: 70px;
        color: $green;
    }

    p {
        margin-top: 20px;
        font-size: 15px;
        line-height: 24px;
        font-family: $ProximaNovaLight;
        text-transform: uppercase;
    }

    .yellow {
        padding: 2px 5px;
        background: $yellow;
        border-radius: 10px;
    }
}

@media (max-width: 1441px) {
    .modal {
        .wrap {
            padding: 50px;
        }

        .form {
            .col {
                flex: 100%;
                max-width: calc(50% - 10px);
            }
        }
    }

    .benefits-list {
        &-title {
            padding: 0;
        }

        &-item {
            padding: 0 20px 0 0;

            &:first-child {
                flex: 100%;
                max-width: 100%;
                padding: 0 0 40px;
            }
        }
    }
}

@media (max-width: 1301px) {
    .modal {
        .title {
            font-size: 55px;
            line-height: 50px;
        }

        .form {
            .btn {
                padding: 19px 30px;
            }
        }

        .offer-block {
            .left-block {
                padding-right: 30px;
            }

            .sub-title {
                padding-left: 30px;
                font-size: 24px;

                &:before {
                    left: 10px;
                    width: 10px;
                }
            }
        }
    }

    .benefits-list {
        &-title {
            font-size: 30px;
            line-height: 30px;
        }

        .num {
            margin: 0 20px 20px 0;
        }

        .title {
            font-size: 20px;
            line-height: 20px;
        }

        p {
            margin-top: 10px;
            font-size: 12px;
            line-height: 20px;
        }
    }
}

@media (max-width: 1025px) {
    .modal {
        .modal-close-btn {
            top: 20px;
            right: 20px;
        }

        .wrap {
            padding: 40px;
        }

        &-wrap {
            &.phone-modal {
                .modal {
                    max-width: 620px;

                    .wrap {
                        padding: 50px;
                    }
                }
            }
        }

        .title {
            font-size: 45px;
            line-height: 40px;
        }

        .form {
            margin-top: 20px;
        }
    }

    .benefits-list {
        margin-top: 20px;

        .title {
            font-size: 20px;
            line-height: 20px;
        }
    }
}

@media (max-width: 769px) {
    .modal {
        .offer-block {
            flex-direction: column;

            .right-block {
                padding: 20px 0 0;
            }
        }
    }
}

@media (max-width: 561px) {
    .modal {
        .modal-close-btn {
            top: 10px;
            right: 10px;
        }

        .wrap {
            padding: 20px;
        }

        &-wrap {
            &.phone-modal {
                .sub-title {
                    max-width: 100%;
                }

                .modal {
                    .wrap {
                        padding: 40px 20px;
                    }
                }
            }
        }

        .title {
            font-size: 40px;
            line-height: 40px;
        }

        .form {
            .col {
                flex: 100%;
                max-width: 100%;
            }

            .col-wrap {
                flex-wrap: wrap;

                .left-col {
                    flex: 100%;
                    padding-right: 0;
                }
                .right-col {
                    flex: 100%;
                    margin-top: 20px;
                    padding-left: 0;
                }
            }

            .input,
            .textarea {
                padding-left: 20px;
                padding-right: 20px;
                font-size: 16px;
            }

            .btn {
                padding: 19px 20px;
            }
        }

        .offer-block {
            .form {
                .row {
                    margin-top: 10px;
                }
            }
        }
    }

    .benefits-list {
        &-item {
            flex: 100%;
            margin: 20px 0 0;
            padding: 0;

            &:first-child {
                margin: 0;
                padding: 0;
            }
        }

        .title {
            font-size: 20px;
            line-height: 20px;
        }
    }
}