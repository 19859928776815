@import '_var.scss';


html, body { 
    height: 100%;
    width: 100%;
    margin: 0; 
    padding: 0;
}

* { 
    box-sizing: border-box;
    outline: none;
}

body {
    font-family: $ProximaNova;
    font-size: 16px;
    color: $textColor;
    overflow-x: hidden;
}

.noscroll {
    overflow: hidden;
}

.mobile {
    display: none !important;
}

.rouble {
    font-family: $rouble;
}

h1, h2, h3,
.h1, .h2, .h3 {
    margin: 0;
    font-family: $ProximaNovaSemibold;
    font-weight: bold;
}
h1, .h1 {
    font-size: 85px;
    line-height: 85px;
}
h2, .h2 {
    font-size: 67px;
    line-height: 67px;
}
h3, .h3 {
    font-size: 46px;
    line-height: normal;
    font-family: $ProximaNova;
    font-weight: normal;
}

.sub-title {
    font-family: $ProximaNovaLight;
    font-size: 51px;
}

a {
    color: $linkColor;
    text-decoration: none;
    outline: none;
    transition: 0.3s;

    &:hover {
        opacity: 0.7;
    }
}

p {
    margin: 30px 0 0;
    font-size: 15px;
    line-height: 25px;
    font-weight: normal;
    &:first-child {
        margin: 0;
    }
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

img {
    max-width: 100%;
}

.btn {
    display: inline-block;
    position: relative;
    padding: 30px 60px;
    background: linear-gradient(to bottom, #25be1d 0%, #1d9619 100%);
    color: $white;
    font-size: 19px;
    font-family: $ProximaNovaExtraBold;
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    border: 0;
    cursor: pointer;
    transition: 0.3s;
    border-bottom: 4px solid $darkGreen;
    box-shadow: -1px 16px 20px -8px $shadowColor;
    border-radius: 20px;

    &:hover {
        opacity: 0.7;
    }
}


/*** Form ***/

form {
    margin: 0;
}

.form {
    .row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        margin: 20px 0 0 -20px;

        &:first-child {
            margin-top: 0;
        }

        .row {
            flex: 100%;
            margin: 20px 0 0;
        }
    }

    .col {
        flex: 1;
        margin-left: 20px;
    }
}

.input-wrap {
    position: relative;

    .label {
        display: block;
        margin-bottom: 20px;
        color: $darkGray;
        font-size: 12px;
        font-family: $ProximaNovaSemibold;
        letter-spacing: 3px;
    }
}

.input,
.textarea {
    width: 100%;
    height: 90px;
    padding: 0 40px;
    line-height: 90px;
    background: $white;
    color: $textColor;
    font-family: $ProximaNovaBoldItalic;
    font-size: 18px;
    font-weight: normal;
    border: 1px solid $borderColor;
    border-radius: 10px;


    &::placeholder {
        color: $darkGray;
        text-transform: uppercase;
    }
}

.textarea {
    width: 100%;
    min-height: 152px;
    padding-top: 20px;
    line-height: normal;
}

@import '_header.scss',
        '_modal.scss',
        '_footer.scss';