.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 190px 100px 100px;

    .center-block {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .logo {
            margin: 0 50px;
        }

        .left-label {
            font-family: $ProximaNovaLight;
            font-size: 14px;
            color: $darkGray;
            text-align: right;
        }
        .right-label {
            font-family: $ProximaNovaLight;
            font-size: 14px;
            color: $darkGray;
        }
    }

    .btn-empty {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 0 30px;
        font-size: 16px;
        font-family: $ProximaNovaSemibold;
        font-weight: 600;
        text-align: center;
        color: $linkColor;
        text-transform: uppercase;
        border: 1px solid $linkColor;
        border-radius: 50px;
    }

    .label {
        font-size: 14px;
        color: $darkGray;
    }

    .phone {
        display: block;
        margin-top: 10px;
        color: $textColor;
        font-size: 23px;
        font-family: $ProximaNovaSemibold;
    }
}

@media (max-width: 1441px) {
    .footer {
        padding: 130px 50px 50px;
    }
}

@media (max-width: 1281px) {
    .footer {
        flex-wrap: wrap;
        padding: 100px 30px 50px;

        .left-block {
            display: flex;
            justify-content: flex-start;
            flex: 50%;
            order: 2;
        }

        .center-block {
            flex: 100%;
            justify-content: center;
            padding-bottom: 40px;
            order: 1;

            .logo {
                max-width: 100px;
                margin: 0 20px;
            }

            .left-label {
                width: 20%;
            }
            .right-label {
                width: 25%;
            }
        }

        .right-block {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            flex: 50%;
            text-align: right;
            order: 3;
        }

        .btn-empty {
            padding: 0 20px;
            font-size: 14px;
        }

        .phone {
            margin-top: 5px;
            font-size: 20px;
        }
    }
}

@media (max-width: 993px) {
    .footer {
        padding: 100px 20px 50px;
    }
}

@media (max-width: 561px) {
    .footer {
        .center-block {
            flex-direction: column;

            .left-label,
            .right-label {
                width: 100%;
                text-align: center;
            }

            .logo {
                margin: 20px 0;
            }
        }

        .left-block,
        .right-block {
            display: flex;
            justify-content: center;
            flex: 100%;
            width: 100%;
        }

        .right-block {
            margin-top: 30px;
            text-align: center;
        }
    }
}